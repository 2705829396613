/* Default styles for body and code elements */
/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Additional styles to prevent page scrolling */
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

@font-face {
  font-family: 'NRT';
  src: url('../Assets/NRT.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  src: url('../src/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('../src/fonts/Barlow/Barlow-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* Add other variations as needed */



body.kurdish-font, 
body.kurdish-font p, 
body.kurdish-font button, 
body.kurdish-font ul, 
body.kurdish-font span,
body.kurdish-font * { 
  font-family: 'NRT', sans-serif;
}

body.english-font, 
body.english-font p, 
body.english-font button, 
body.english-font ul, 
body.english-font span,
body.english-font * {
  font-family: 'Barlow', sans-serif !important; /* Use Barlow by default for English */

}