/* Pricing.css */

/* Style the pricing list container */
.pricing-container {
    margin-top: 20px;
  }
  
  /* Style each pricing row */
  .pricing-item {
    list-style: none;
    border: 1px solid #000000;
    padding: 20px;
    margin-left: 0px;
    margin-right: 30px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Style the pricing item name */
  .pricing-item-name {
    flex: 1; /* Take up available space */
    font-weight: bold;
  }
  .pricing-item-content {
    display: flex;
    align-items: center;
  }
  /* Style the "Edit" button */
  .pricing-item-button {
    background-color: #1d344c; /* Dark navy background color */
    color: #fff; /* Text color (white) */
    border: none; /* Remove border */
    border-radius: 5px; /* Rounded corners */
    padding: 10px 20px; /* Padding for the button */
    font-size: 16px; /* Font size */
    cursor: pointer; /* Add pointer cursor on hover */
    margin-right: 50px; /* Margin right for spacing */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth hover transition */
  }
  
  .pricing-item-button:hover {
    background-color: #1d344c; /* Darker navy on hover */
  }
  
  
  /* Style the pricing item value */
  .pricing-item-value {
    font-weight: bold;
    font-size: 20px;
    color: #00102b; /* You can use your preferred color */
  }
  

  .pricing-scroll-container {
    max-height: 90vh; /* Set the maximum height for scrollability */
    overflow-y: auto; /* Add a vertical scrollbar when the content exceeds the container's height */
  }
  
  /* Add this style to prevent horizontal scrollbar */
  .pricing-container {
    overflow-x: hidden;
  }