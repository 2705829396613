/* Dashboard.css */

.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  background-color: #f4f4f8;
  font-family: 'Arial', sans-serif;
}

.dashboard-header {
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-header h1 {
  font-size: 24px;
  font-weight: bold;
}

/* Overview and tasks container */
.left-column, .right-column {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
}

.overview {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.overview-card {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  flex-grow: 1;
  margin: 0 10px;
}

.overview-title {
  font-size: 16px;
  font-weight: bold;
  color: #555;
}

.overview-number {
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0;
}

.overview-change {
  font-size: 14px;
  color: #666;
}

/* Who's Away and Team by Country styles */
.whos-away, .team-by-country {
  background-color: #ffffff;
  padding: 1rem;
  margin-bottom: 20px;
  border-radius: 8px;
}

.whos-away h2, .team-by-country h2 {
  margin-bottom: 1rem;
}

.whos-away ul, .team-by-country ul {
  list-style: none;
  padding: 0;
}

.whos-away li, .team-by-country li {
  padding: 0.5rem 0;
  border-bottom: 1px solid #e0e0e0;
}

.whos-away li:last-child, .team-by-country li:last-child {
  border-bottom: none;
}

/* Tasks styles */
.tasks h2 {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

.task-list {
  display: flex;
  flex-direction: column;
}

.task-item {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.task-title {
  font-size: 16px;
  font-weight: bold;
}

.task-due-date {
  font-size: 14px;
  color: #666;
}

.task-item.overdue .task-due-date {
  color: red;
}

/* Additional global styles */
.dashboard-content {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}
