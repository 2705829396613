.centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 45%;
    max-width: 400px; /* Adjust the maximum width as needed */
    margin: 80px auto 20px auto; /* Added margin-top to create a gap below TopBar */
    border: 2px solid #1d344c; /* Border color */
    padding: 60px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional box shadow */
  }
  .centered-container h2 {
    font-size: 34px; /* Increase this value to your desired font size */
    /* Add other styling properties as needed */
  }
  
  
  .centered-container input,
  .centered-container button {
    margin: 25px 0;
    width: 80%;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .centered-container button {
    margin-top: 60px; /* Increase this value to increase the gap */

    background-color: #1d344c;
    color: white;
    cursor: pointer;
    width:80%;
  }
  
  .centered-container button:hover {
    background-color: #2980b9;
  }
  
  .copyright-text {
    text-align: center;
    position: absolute;
    bottom: 10px; /* Adjust this value to control the vertical position */
    width: 100%;
  }