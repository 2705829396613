/* Change background color and text color for light mode */
.App.light-mode {
  background-color: #ffffff; /* Light background color */
  color: #000000; /* Dark text color */
}

/* Remove background color and set text color for light mode */
.App.light-mode .App-header {
  background-color: transparent; /* Remove background color */
  color: #000000; /* Dark text color */
}

/* Change link color for light mode */
.App.light-mode .App-link {
  color: #007acc; /* Light blue link color */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner-small {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 2s linear infinite;
}

.spinner-large {
  border: 8px solid #f3f3f3; /* Light grey border, represents the background */
  border-top: 8px solid #3498db; /* Blue border, represents the spinning part */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin: auto; /* Centers the spinner horizontally */
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Add the keyframes and animated gradient container CSS here */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animatedGradientContainer {
  animation: gradientAnimation 5s ease infinite;
}
